// @flow
import React from 'react';
// Styles
import './PromotionNotice.css';
// Components
import ProgressionBar from './ProgressionBar';
// Helpers
import { priceConverter } from '../../common';
// Types
type Props = {
  totalCartPrice: number,
};

const PromotionNotice = ({ totalCartPrice = 0 }: Props) => {
  return (
    <div className="promotion-notice">
      {totalCartPrice >= 250 ? (
        <>
          <span className="promotion-notice-text">
            Seu{' '}
            <span className="promotion-notice-text-orange-bold">
              boné exclusivo
            </span>{' '}
            já{' '}
            <span className="promotion-notice-text-orange-bold">
              está disponível!
            </span>
          </span>
        </>
      ) : (
        <>
          <span className="promotion-notice-text">
            <span className="promotion-notice-text-orange-bold">
              Só faltam{' '}
            </span>
            {priceConverter(250 - totalCartPrice)} para você ganhar um{' '}
            <span className="promotion-notice-text-orange-bold">
              presente exclusivo!
            </span>
          </span>
        </>
      )}
      <ProgressionBar
        absoluteValueToCompare={250}
        valueToBeCompared={totalCartPrice}
        barColor="linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%)"
      />
    </div>
  );
};

export default PromotionNotice;
